<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      <v-row class="mx-3">
        <v-col md="12" class="py-1">
          <div class="form-action">
            <div class="form-title">
              Visit
              <span class="form-title-link cursor-pointer">
                #{{ jobOption.visit_barcode }}
              </span>
              created for
              <span class="form-title-link cursor-pointer">
                {{ vCustomer.display_name }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>
    <template v-slot:body>
      <!-- <div
        v-if="doUpdate == 1 && scheduleTab == 'recurring'"
        class="p-4 font-size-16 font-weight-400 warning-background pbn d-flex"
      >
        <p class="d-flex align-center font-weight-600">
          <v-icon color="red darken-3" class="mr-2" size="55">mdi-alert</v-icon>
        </p>
        <p>
          <span
            class="font-weight-600 red--text text--darken-3 font-size-custom"
            >If you Update the reccuring visit then all previously visits will
            be deleted and create new visits with same data. Are you sure about
            updating it?</span
          >
        </p>
      </div> -->
      <div class="px-4 w-100">
        <v-row>
          <v-col cols="6">
            <label class="my-0">Site Location</label>
            <template v-if="vProperty?.id">
              <p class="mb-0">{{ vProperty.property_address }}</p>
            </template>
            <label class="my-0 mt-3">Contact Details</label>
            <template v-if="vPropertyContact?.id">
              <p class="mb-0">{{ vPropertyContact.display_name }}</p>
              <p class="mb-0">{{ vPropertyContact?.primary_phone }}</p>
              <p class="mb-0">{{ vPropertyContact?.primary_email }}</p>
            </template>
            <!--  <v-layout class="mt-3">
              <v-flex md4
                ><label class="my-0">Email Notification </label></v-flex
              >
              <v-flex md8>
                <v-switch
                  readonly
                  v-model="dbVisit.property_contact_person"
                  class="m-0 p-0"
                  color="cyan"
                  dense
                  inset
                />
              </v-flex>
            </v-layout> -->
          </v-col>
          <v-col cols="6" class="custom-border-left">
            <template v-if="assignedTeamObject?.length">
              <label class="my-0">Technicians</label>
              <v-row>
                <v-col
                  cols="8"
                  v-for="(row, index) in assignedTeamObject"
                  :key="`route-dialog-team-${index}`"
                >
                  <div class="d-flex align-center">
                    <v-avatar size="75">
                      <v-img
                        v-if="row?.engineer_profile_logo"
                        :src="row?.engineer_profile_logo"
                      ></v-img>
                      <v-img v-else :src="$defaultProfileImage"></v-img>
                    </v-avatar>
                    <div class="ml-2">
                      <v-chip
                        small
                        label
                        color="cyan white--text"
                        class="font-weight-600 mb-1"
                        >{{ row.engineer_barcode }}</v-chip
                      >
                      <p
                        class="my-0 font-weight-600"
                        v-if="row.engineer_display_name"
                      >
                        <v-icon small class="mr-2">mdi-account</v-icon
                        >{{ row.engineer_display_name }}
                      </p>
                      <p
                        class="my-0 font-weight-600 d-flex"
                        v-if="row.engineer_primary_email"
                      >
                        <v-icon small class="mr-2">mdi-email</v-icon
                        >{{ row.engineer_primary_email }}
                      </p>
                      <p
                        class="my-0 font-weight-600"
                        v-if="row.engineer_primary_phone"
                      >
                        <v-icon small class="mr-2">mdi-phone</v-icon
                        >{{ row.engineer_primary_phone }}
                      </p>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <div class="p-4 font-size-16 font-weight-400 warning-background">
                <p class="d-flex align-center font-weight-600">
                  <v-icon color="red darken-3" class="mr-2"
                    >mdi-alert-circle-outline</v-icon
                  >
                  Attention...
                </p>
                <p>
                  This is a friendly reminder that
                  <span class="font-weight-600 red--text text--darken-3"
                    >an technician has not been assigned to this visit</span
                  >. It's crucial to ensure that every visit has a dedicated
                  technician to guarantee a smooth and successful outcome.
                </p>
                <p class="mb-0">
                  Please review the visit schedule and promptly assign a
                  qualified technician to the pending visit to avoid any
                  potential delays or complications. A well-coordinated effort
                  ensures the best results for our projects and maintains our
                  commitment to excellence.
                </p>
                <v-btn
                  v-on:click="$emit('scroll-to', 'engineer')"
                  color="cyan darken-3 white--text"
                  class="mt-3 font-size-22 custom-bold-button"
                  depressed
                  >Add Technician</v-btn
                >
              </div>
            </template>
            <template v-if="false">
              <p class="mb-1">
                <label class="my-0">Priority</label> {{ dbVisit.priority }}
              </p>
              <p class="mb-1">
                <label class="my-0">Job Type</label> {{ dbVisit.job_type }}
              </p>
              <p class="mb-1">
                <label class="my-0">Tag</label> {{ dbVisit.tags }}
              </p>
            </template>
          </v-col>
          <v-col cols="12">
            <p
              class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
              v-if="scheduleTab == 'one-off'"
            >
              This job will start on
              {{ formatDate(oneOffSchedule.start_date) }}
              {{ oneOffSchedule.start_time }} and finish on
              {{ formatDate(oneOffSchedule.end_date) }}
              {{ oneOffSchedule.end_time }}
            </p>
            <div v-if="scheduleTab == 'recurring'">
              <p
                v-if="recurringSchedule"
                class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border"
              >
                {{ recurringSchedule?.message }}
              </p>
              <div
                v-else
                class="warning-background m-0 p-4 font-size-16 font-weight-400 mt-4"
              >
                <p class="d-flex align-center font-weight-600">
                  <v-icon color="red darken-3" class="mr-2"
                    >mdi-alert-circle-outline</v-icon
                  >
                  Attention...
                </p>
                <p class="mb-0">
                  We have observed that
                  <span class="font-weight-600 red--text text--darken-3"
                    >schedules have not been created for this visit</span
                  >. Having a well-defined schedule is essential for proper
                  planning and execution.
                </p>
                <p class="mb-0">
                  Kindly take immediate action to establish a detailed schedule
                  for the visit. This will help us effectively allocate
                  resources and manage tasks to achieve a successful outcome.
                </p>
                <v-btn
                  v-on:click="$emit('scroll-to', 'engineer')"
                  color="cyan darken-3 white--text"
                  class="mt-3 font-size-22 custom-bold-button"
                  depressed
                  >Manage Schedule</v-btn
                >
              </div>
            </div>
            <div
              v-if="!vSelectedLineItem.length"
              class="warning-background m-0 p-4 font-size-16 font-weight-400 mt-4"
            >
              <p class="d-flex align-center font-weight-600">
                <v-icon color="red darken-3" class="mr-2"
                  >mdi-alert-circle-outline</v-icon
                >
                Attention...
              </p>
              <p class="mb-0">
                We have noticed that
                <span class="font-weight-600 red--text text--darken-3"
                  >the line items for this visit have not been added</span
                >. It's important to include all relevant line items to ensure
                the visit's success.
              </p>
              <p class="mb-0">
                Please review and update the visit details with the necessary
                line items. Thank you for your attention to this matter.
              </p>
              <v-btn
                v-on:click="$emit('scroll-to', 'lineItem')"
                color="cyan darken-3 white--text"
                class="mt-3 font-size-22 custom-bold-button"
                depressed
                >Add Line Items</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </div>
    </template>
    <template v-slot:action>
      <v-btn
        v-if="false"
        :disabled="loading"
        :loading="loading"
        color="green darken-3 white--text"
        class="font-size-22 custom-bold-button"
        depressed
        v-on:click="$emit('submit', 'new')"
        >Save &amp; Create new Visit</v-btn
      >
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="brown white--text"
        class="font-size-22 custom-bold-button"
        depressed
        v-on:click="$emit('submit', 'detail')"
        >Save &amp; Go To Detail</v-btn
      >
      <v-btn
        :disabled="loading"
        :loading="loading"
        color="orange darken-3 white--text"
        class="font-size-22 custom-bold-button"
        depressed
        v-on:click="$emit('submit', 'listing')"
        >Save &amp; Go To Listing</v-btn
      >
      <v-btn
        :disabled="loading"
        :loading="loading"
        class="font-size-22 custom-bold-button"
        depressed
        v-on:click="$emit('close', true)"
        >Cancel</v-btn
      >
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";

export default {
  mixins: [CommonMixin],
  name: "route-dialog",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    doUpdate: {
      type: Number,
      default: 0,
    },
    scheduleTab: {
      type: String,
      default: "one-off",
    },
    dbVisit: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  components: {
    Dialog,
  },
  methods: {},
  computed: {
    ...mapGetters([
      /* "vTeam",
      "vOption", */
      "vCustomer",
      "vProperty",
      /* "vSchedule", */
      "vSelectedLineItem",
      /*   "vRecurringSchedule", */
      "vPropertyContact",

      "assignedTeamObject",
      "jobOption",
      "oneOffSchedule",
      "recurringSchedule",
    ]),
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  padding-left: 5px;
  font-weight: 600;
}
p {
  color: #000000de;
  padding-left: 5px;
}

.form-action {
  display: flex;
  justify-content: space-between;

  .form-title {
    font-size: 24px;
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #24326d;
    letter-spacing: 1.5px;
    line-height: 1.11em;
    .form-title-link {
      color: #4d6974;
      border-bottom: dashed 2px #4d6974;
    }
  }
}
</style>
