<template>
  <div class="schedule-tab-item ml-3">
    <div class="width-35 custom-schedule-calendar mx-auto">
      <v-btn fab text small v-on:click="prev" color="grey darken-2">
        <v-icon small> mdi-chevron-left </v-icon>
      </v-btn>
      <v-toolbar-title
        class="margin-auto font-weight-500"
        v-if="$refs.scheduleCalendar"
      >
        {{ $refs.scheduleCalendar.title }}
      </v-toolbar-title>
      <v-btn fab text small v-on:click="next" color="grey darken-2">
        <v-icon small> mdi-chevron-right </v-icon>
      </v-btn>
    </div>
    <div class="width-100">
      <v-calendar
        ref="scheduleCalendar"
        type="month"
        color="cyan"
        v-model="scheduleCalendar"
        :events="events"
        :event-color="getEventColor"
        v-on:click:event="showEvent"
      >
        <template v-slot:day="day">
          <!--   <div v-if="getHoliday(day?.date)" class="holiday-date"></div> -->
          <v-tooltip
            top
            content-class="custom-top-tooltip"
            v-if="getHoliday(day.date)"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
                style="width: 97%"
                color="black"
                class="ml-1"
                label
                outlined
                text-color=""
              >
                <span>
                  <div></div>
                </span>
                <span class="font-size-10 font-weight-500 text-capitalize">
                  Holiday
                </span>
              </v-chip>
            </template>
            <span> Holiday</span>
          </v-tooltip>
          <div :class="`d-none day-${day.weekday}`"></div>
        </template>
        <template v-slot:event="{ event }">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                small
                v-bind="attrs"
                v-on="on"
                style="width: 97%"
                :color="event.color"
                class="ml-1 text-white"
                label
              >
                <span>
                  <div></div>
                </span>
                <span class="font-size-10 font-weight-500 text-capitalize">
                  {{ event.name }}
                  <!--  {{ getEvents(event) }} -->
                </span>
              </v-chip>
            </template>
            <span> {{ event.status_text }}</span>
          </v-tooltip>
        </template>
      </v-calendar>
      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card color="grey lighten-4" min-width="350px" flat>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon v-on:click="selectedOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <span v-html="selectedEvent.details"></span>
          </v-card-text>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  data() {
    return {
      scheduleCalendar: null,
      events: new Array(),
      holidayData: new Array(),
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  props: {
    showCalendar: Boolean,
  },
  methods: {
    getHoliday(date) {
      console.log(date);
      if (date && this.holidayData?.length > 0) {
        const isDatePresent = this.holidayData.some(
          (event) => event.date == moment(date).format("YYYY-MM-DD")
        );
        return isDatePresent;
      }
    },
    prev() {
      this.$refs.scheduleCalendar.prev();
    },
    next() {
      this.$refs.scheduleCalendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    getEventColor(event) {
      return event.color;
    },
    updateEvents() {
      const _this = this;

      _this.$store
        .dispatch(QUERY, { url: "calendar/visits" })
        .then(({ data }) => {
          _this.events = data.event;
          _this.holidayData = data.holiday;
        })
        .catch((error) => {
          _this.logError(error);
        });

      /*this.events.push({
        name: 'Installation Mobile Devices',
        start: new Date(),
        end: new Date('2021-01-14'),
        details: '<h1>Hello World</h1>',
        color: 'cyan',
        timed: true,
      })*/
    },
  },
  mounted() {
    this.updateEvents();
    this.$refs.scheduleCalendar.checkChange();
  },
};
</script>
